.blink{
	/*
		width:200px;
		height: 50px;
    background-color: magenta;
		padding: 15px;
		text-align: center;
		line-height: 50px;
		*/
		animation: blink 1s linear infinite;
	}
	/*
	span{
		font-size: 25px;
		font-family: cursive;
		color: white;
		animation: blink 1s linear infinite;
	}
	*/

@keyframes blink{
0%{opacity: 0;}
50%{opacity: .5;}
100%{opacity: 1;}
}
