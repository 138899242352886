.app {
  display: grid;
  grid-template-columns: auto auto auto auto;
  justify-content: space-between;
  justify-items: center;
}

.header {
  grid-column-start: 1;
  grid-column-end: 5;
  justify-self: stretch;
  align-self: start;
  text-align: center;
  padding: 24px;
}

.hero {
  grid-column-start: 1;
  grid-column-end: 5;
  padding: 16px;
}

.results {
  grid-column-start: 1;
  grid-column-end: 5;
  padding: 16px;
  width:80%;
}

.results .results__result {
  display: flex;
  border-bottom: 1px solid grey;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.button {
  width: 100%;
  background: #61dafb;
  border-radius: 4px;
  padding: 8px;
  border: none;
  cursor: pointer;
  font-size: 12px;
  color: white;
  font-weight: bold;
}

.button:hover, .button:active {
  background: #54bedb
}

.fileUploaderContainer {
  overflow: hidden;
  display: block;
  position: relative;
  color: black;
  font-weight: bold;
  padding: 8px;
  margin-bottom: 8px;
  /*border: 3px dashed #777777;*/
  border: 3px inset #3f51b5;
  border-radius: 8px;
}

.fileUploaderContainer [type=file] {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  min-height: 100%;
  min-width: 100%;
  font-size: 999px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  cursor: pointer;
}
